import Clock from 'react-live-clock';
import { useSelector } from 'react-redux';
import { DATE_DEFUALT_FORMAT } from 'src/utils/staticObjects';

export default function CustomClock() {
  const timeFormat = useSelector(
    state => state.account?.user?.personalization?.timeFormat
  );
  const timeZone = useSelector(
    state => state.account?.user?.personalization?.timeZone
  );
  return (
    <Clock
      style={{ cursor: 'default' }}
      format={timeFormat ?? DATE_DEFUALT_FORMAT}
      ticking={true}
      timezone={timeZone ?? 'Europe/London'}
    />
  );
}
