import _ from 'lodash';
import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo, useState } from 'react';
import { LanguagesEnum, THEMES } from 'src/constants';
import { storeSettings } from 'src/utils/settings';
import { useSelector } from 'react-redux';
import { accountSelector, companySelector } from '../selectors/selectors';

const SettingsContext = createContext();

const defaultSettings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  theme: THEMES.LIGHT.name,
  lang: 'en',
  isDefaultSettings: true
};

let langParamOverride = false;
export function SettingsProvider({ settings, children }) {
  const [currentSettings, setCurrentSettings] = useState(
    settings || defaultSettings
  );
  const account = useSelector(accountSelector);
  const userLang = account?.user?.personalization?.lang;
  const { companyDefaultLanguage } = useSelector(companySelector);
  const { translations } = useSelector(state => state?.userInterface);
  const translationKeysArray = useMemo(
    () => (translations ? Object.keys(translations) : null),
    [translations]
  );

  useEffect(() => {
    if (!translations) return;

    const search = window.location.search;
    const queryParams = new URLSearchParams(search);

    let lang = queryParams?.get('lang')?.toUpperCase();
    if (lang === 'IL') lang = 'IW';

    if (lang) {
      queryParams.delete('lang');
      window.history.replaceState(
        null,
        '',
        `${window.location.pathname}?${queryParams.toString()}`
      );

      if (translationKeysArray?.includes(lang)) {
        const language = LanguagesEnum?.[lang];
        handleSaveSettings({
          lang: language.lang,
          direction: language.direction
        });
        langParamOverride = true;
      } else {
        getAndSaveUserLanguages();
      }
    } else {
      getAndSaveUserLanguages();
    }
  }, [companyDefaultLanguage, userLang, translations]);

  const getAndSaveUserLanguages = () => {
    if (langParamOverride) return;
    if (translationKeysArray?.includes(currentSettings?.lang?.toUpperCase()))
      return;
    const language = translationKeysArray?.includes(userLang?.toUpperCase())
      ? LanguagesEnum[userLang?.toUpperCase()]
      : LanguagesEnum[companyDefaultLanguage];
    if (!language) return;
    console.log(language);
    handleSaveSettings({
      lang: language.lang,
      direction: language.direction
    });
  };
  const handleSaveSettings = (updatedSettings = {}) => {
    const mergedSettings = _.merge({}, currentSettings, updatedSettings);
    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  useEffect(() => {
    document.dir = currentSettings.direction;
  }, [currentSettings]);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.object
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
