import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { accountSelector } from '../selectors/selectors';
import authService from '../services/authService';

function AuthGuard({ children }) {
  const account = useSelector(accountSelector);
  const { isUnauthorized } = useSelector(state => state.userInterface);

  const accessToken = authService.getAccessToken();
  if (accessToken == null) {
    authService.logout();
    return <Redirect to="/login" />;
  }
  const accessTokenValid = !!accessToken && accessToken !== '';

  if (!accessTokenValid || !account.user || isUnauthorized) {
    return <Redirect to="/login" />;
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
