import { store } from 'src';
import ajaxUtils from '../utils/ajaxUtils';
import { get } from '../utils/axios';
import { COMPANY_BRANDING, COMPANY_INITIAL_DATA } from './companyActions';
import { DOC_REQ } from './userActions';

export const CONFIG_INITIALIZE = '@config/config-initialize';
export const CONFIG_IS_MOBILE_NAV_OPEN = '@config/is-mobile-nav-open';

export function getCompanyInitialData() {
  if (window.location.pathname.toLocaleLowerCase() == '/invaliddomain')
    return null;
  const request = get(ajaxUtils.configActions.companyInitialData, {});
  return () => {
    request
      .then(response => {
        if (!response.data.error) {
          store.dispatch({
            type: CONFIG_INITIALIZE,
            payload: response.data.config
          });
          store.dispatch({
            type: COMPANY_INITIAL_DATA,
            payload: response.data
          });
          store.dispatch({
            type: COMPANY_BRANDING,
            payload: response.data.branding
          });
        }
      })
      .catch(err => {
        console.warn(err);
      });
  };
}

export function updateUserDocReq() {
  if (window.location.pathname.toLocaleLowerCase() == '/invaliddomain')
    return null;
  get(ajaxUtils.configActions.affiliateDocReq, {})
    .then(response => {
      store.dispatch({
        type: DOC_REQ,
        payload: !response.data
      });
    })
    .catch(err => {
      console.warn(err);
    });
}
