import ajaxUtils from '../utils/ajaxUtils';
import { get } from '../utils/axios';
import { dateToLocalDateTime } from '../utils/utils';

export const COMPANY_TRAFFIC_SOURCE = '@company/traffic-sources';
export const COMPANY_PAYMENT_METHODS = '@company/payment-methods';
export const COMPANY_ADVERTISERS = '@company/advertisers';
export const COMPANY_MERCHANT = '@company/merchant';
export const COMPANY_CURRENCY = '@company/currency';
export const COMPANY_BRANDING = '@company/branding';
export const COMPANY_LOGO_OVERRIDE = '@company/company-logo-override';
export const COMPANY_INITIAL_DATA = '@company/initial-data';

export function getTrafficSources() {
  const request = get(ajaxUtils.companyActions.trafficSources, {});
  return dispatch => {
    request
      .then(response => {
        if (!response.data.error) {
          dispatch({
            type: COMPANY_TRAFFIC_SOURCE,
            payload: response.data
          });
        }
      })
      .catch(err => {
        console.warn(err);
      });
  };
}
export function getAdvertisersSummary() {
  const request = get(ajaxUtils.companyActions.advertisers, {});
  return dispatch => {
    request
      .then(response => {
        if (!response.data.error) {
          dispatch({
            type: COMPANY_ADVERTISERS,
            payload: response.data
          });
        }
      })
      .catch(err => {
        console.warn(err);
      });
  };
}

export function getCurrency() {
  const request = get(ajaxUtils.companyActions.currency, {});
  return dispatch => {
    request
      .then(response => {
        if (!response.data.error) {
          dispatch({
            type: COMPANY_CURRENCY,
            payload: { sign: response.data.sign, ...response.data }
          });
        }
      })
      .catch(err => {
        console.warn(err);
      });
  };
}

export async function getAllCurrencies() {
  const request = await get(`${ajaxUtils.companyActions.currency}/all`, {});
  return request?.data;
}

export function getMerchant() {
  const request = get(ajaxUtils.companyActions.merchant, {});
  return async dispatch => {
    request
      .then(async response => {
        if (!response.data.error) {
          await dispatch({
            type: COMPANY_MERCHANT,
            payload: response.data
          });
        }
      })
      .catch(err => {
        console.warn(err);
      });
  };
}

export const getCompanyCountries = async () => {
  const response = await get(
    `${ajaxUtils.userActions.affiliate.companies}/countries`
  );
  return response?.data;
};

export const getCompanyPrivacySettings = async () => {
  const response = await get(`${ajaxUtils.companyActions.settings}/privacy`);
  return response?.data;
};

export const getFilteredConversionStats = async (
  filters,
  pageNumber,
  pageSize,
  type = 0
) => {
  const startDate = filters?.startDate
    ? dateToLocalDateTime(filters.startDate)
    : null;
  const endDate = filters?.endDate
    ? dateToLocalDateTime(filters.endDate)
    : null;
  const campaignSerialOids = filters.selectedCampaigns
    .map(campaign => campaign.value)
    .toString();
  const subId = filters ? filters.selectedSubId : null;
  const timePeriod = filters ? filters.selectedTimePeriodId : null;
  const statuses =
    filters.selectedStatuses != null && filters.selectedStatuses.length > 0
      ? filters.selectedStatuses.map(status => status.value).toString()
      : null;
  const subIdText = filters.subIdText || null;
  const comment = filters.comment || null;
  const customStatuses =
    filters.selectedCustomStatuses != null &&
    filters.selectedCustomStatuses.length > 0
      ? filters.selectedCustomStatuses.map(status => status.value).toString()
      : null;

  let url;
  switch (type) {
    case 0:
      url = ajaxUtils.companyActions.statistics.conversions.filtered;
      break;
    case 1:
      url = ajaxUtils.companyActions.statistics.impressions.get;
      break;
    case 2:
      url = ajaxUtils.companyActions.statistics.clicks.get;
      break;

    default:
      url = ajaxUtils.companyActions.statistics.conversions.filtered;
      break;
  }

  const response = await get(url, {
    startDate,
    endDate,
    campaignSerialOids,
    subId,
    timePeriod,
    statuses,
    subIdText,
    comment,
    pageNumber,
    pageSize,
    customStatuses
  });
  console.log(response);
  return response?.data;
};

export const getTermsAndConditions = async () => {
  const response = await get(ajaxUtils.companyActions.termsAndConditions);
  return response?.data;
};

export const getPrivacyPolicy = async () => {
  const response = await get(ajaxUtils.companyActions.privacyPolicy);
  return response?.data;
};

export const getCompanyCustomFields = async () => {
  const response = await get(ajaxUtils.companyActions.customFields);
  return response?.data;
};

export const getAllSymbols = async () => get(ajaxUtils.groupings.symbol.getAll);
