import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { accountSelector } from '../../selectors/selectors';

export const userHasScope = (account, scope) => {
  return (
    !scope ||
    (account?.user && account?.user?.userPermissions == null) ||
    account?.user?.userPermissions?.[scope]
  );
};
function ScopeGuard({ scope, children }) {
  const account = useSelector(accountSelector);
  if (userHasScope(account, scope)) {
    return children;
  } else return null;
}

ScopeGuard.propTypes = {
  children: PropTypes.any,
  scope: PropTypes.any
};

export default ScopeGuard;
