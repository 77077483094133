import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { companySelector } from '../../selectors/selectors';

function SocialGuard({ children, redirect = false }) {
  const { socialMode } = useSelector(companySelector);

  if (!socialMode) {
    if (redirect) return <Redirect to="/app/reports/dashboard" />;
    return null;
  }
  return children;
}

SocialGuard.propTypes = {
  children: PropTypes.any
};

export default SocialGuard;
