import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { store } from 'src';
import { companySelector } from '../../selectors/selectors';

/**
 * Enum representing different site types.
 * @readonly
 * @enum {string}
 */
export const SiteTypeEnum = {
  /**
   * General site type.
   */
  GENERAL: 'GENERAL',
  /**
   * Broker site type.
   */
  BROKER: 'BROKER',
  /**
   * Casino site type.
   */
  CASINO: 'CASINO',
  /**
   * Network site type.
   */
  NETWORK: 'NETWORK',
  /**
   * IGaming Network site type.
   */
  IGAMING_NETWORK: 'IGAMING_NETWORK'
};

/**
 * Function for guarding routes based on site type.
 * @param {SiteTypeEnum} [props.currentSiteType=null] - current Site Type from redux if not passed taken from store.
 * @param {SiteTypeEnum[]} [props.allowedTypes=[]] - The allowed site types using siteTypeEnum.
 * @returns {boolean} The current type is in allowed list.
 */
export const isSiteTypeInList = (allowedTypes = [], currentSiteType = null) => {
  const siteType = currentSiteType ?? store.getState()?.company?.siteType;
  return allowedTypes.some(at => siteType === at);
};

/**
 * Component for guarding routes based on site type.
 * @param {object} props - The component props.
 * @param {React.ReactNode} props.children - The child elements.
 * @param {boolean} [props.redirect=false] - Whether to redirect if site type is not allowed.
 * @param {SiteTypeEnum[]} [props.allowedTypes=[]] - The allowed site types using siteTypeEnum.
 * @returns {React.ReactNode} The guarded child elements or redirect component.
 */
function SiteTypeGuard({ children, redirect = false, allowedTypes = [] }) {
  const { siteType } = useSelector(companySelector);

  if (!isSiteTypeInList(allowedTypes, siteType)) {
    if (redirect) return <Redirect to="/app/reports/dashboard" />;
    return null;
  }
  return children;
}

/**
 * Prop types for the BrokerGuard component.
 * @type {object}
 * @property {React.ReactNode} children - The child elements.
 * @property {boolean} [redirect=false] - Whether to redirect if site type is not allowed.
 * @property {siteTypeEnum[]} [allowedTypes=[]] - The allowed site types.
 *
 */
SiteTypeGuard.propTypes = {
  children: PropTypes.node.isRequired,
  redirect: PropTypes.bool,
  allowedTypes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(SiteTypeEnum)))
};

SiteTypeGuard.defaultProps = {
  redirect: false,
  allowedTypes: []
};

export default SiteTypeGuard;
