import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { companySelector } from '../../selectors/selectors';

export const eightCapOIds = [961];

export const isEightCapAffiliate = () => {
  const { oid } = useSelector(companySelector);
  return eightCapOIds.includes(oid);
};

function EightCapGuard({ children, redirect = false, active = true }) {
  if (!active) return children;
  if (isEightCapAffiliate()) {
    if (redirect) return <Redirect to="/app/reports/dashboard" />;
    return null;
  }
  return children;
}

EightCapGuard.propTypes = {
  children: PropTypes.node.isRequired,
  redirect: PropTypes.bool,
  active: PropTypes.bool
};

export default EightCapGuard;
