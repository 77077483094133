import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  Portal,
  Typography
} from '@mui/material';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { getPrivacyPolicy } from 'src/actions/companyActions';
import { isURL, stripHtml } from 'src/utils/utils';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    maxWidth: 600,
    position: 'fixed',
    bottom: 0,
    left: 0,
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    outline: 'none',
    zIndex: 2000
  },
  action: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black
    }
  }
}));

function CookiesNotification() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isPrivicyModalOpen, setIsPrivicyModalOpen] = useState(false);
  const [merchantPrivacyPolicy, setMerchantPrivacyPolicy] = useState();

  const handleClose = () => {
    Cookies.set('consent', 'true');
    setOpen(false);
  };

  useEffect(() => {
    const consent = Cookies.get('consent');

    if (!consent) {
      fetchCompanyPrivacyPolicy().finally(() => setOpen(true));
    }
  }, []);

  const fetchCompanyPrivacyPolicy = async () => {
    const currentPrivacy = await getPrivacyPolicy();
    if (currentPrivacy?.privacyPolicy) {
      setMerchantPrivacyPolicy(currentPrivacy?.privacyPolicy);
    }
  };

  const handleOpenPrivacy = () => {
    if (isURL(stripHtml(merchantPrivacyPolicy)))
      return window
        .open(stripHtml(merchantPrivacyPolicy).trim(), '_blank')
        .focus();

    setIsPrivicyModalOpen(true);
  };

  if (!open) {
    return null;
  }

  return (
    <>
      <Dialog
        open={!!isPrivicyModalOpen}
        keepMounted
        onClose={() => setIsPrivicyModalOpen(false)}
      >
        <DialogTitle>
          <FormattedMessage id="privacy_policy" />
        </DialogTitle>
        <DialogContent>
          <div
            style={{ whiteSpace: 'pre-wrap' }}
            dir="auto"
            dangerouslySetInnerHTML={{ __html: merchantPrivacyPolicy }}
          />
        </DialogContent>
      </Dialog>
      <Portal
        sx={{
          direction: 'ltr /* @noflip */',
          textAlign: 'ltr /* @noflip */'
        }}
      >
        <div className={classes.root}>
          <Typography
            sx={{
              direction: 'ltr /* @noflip */',
              textAlign: 'ltr /* @noflip */'
            }}
            variant="body1"
            color="inherit"
          >
            This website uses cookies to provide you with a great user
            experience. By using it, you accept our{' '}
            <Link
              component="a"
              color="inherit"
              underline="always"
              onClick={handleOpenPrivacy}
              sx={{ cursor: 'pointer' }}
              target="_blank"
            >
              Privacy Policy
            </Link>
            .
          </Typography>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              onClick={handleClose}
              variant="contained"
              className={classes.action}
            >
              I Agree
            </Button>
          </Box>
        </div>
      </Portal>
    </>
  );
}

export default CookiesNotification;
