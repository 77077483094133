import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useSettings from 'src/hooks/useSettings';
import { setMomentDefualts } from 'src/utils/utils';
import rtlPlugin from 'stylis-plugin-rtl';

// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [rtlPlugin]
});

function RTL({ isRtl, children }) {
  const timeFormat = useSelector(
    state => state.account?.user?.personalization?.timeFormat
  );
  const timeZone = useSelector(
    state => state.account?.user?.personalization?.timeZone
  );
  const { settings } = useSettings();

  useEffect(() => {
    setMomentDefualts(timeZone, timeFormat, settings.lang);
  }, [timeFormat, timeZone]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {isRtl ? (
        <CacheProvider value={cacheRtl}>{children}</CacheProvider>
      ) : (
        children
      )}
    </LocalizationProvider>
  );
}
export default RTL;
