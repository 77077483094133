const userErrorMessageEnum = {
  emailRequired: 'Email is required',
  firstNameRequired: 'First name is required',
  enterValidEmail: 'Must be a valid email',
  passwordRequired: 'Password is required',
  lastNameRequired: 'Last name is required',
  nameRequired: 'Name is required',
  companyNameRequired: 'Company name is required',
  mustBeChecked: 'This field must be checked',
  passwordsMustMatch: 'Both passwords must be the same',
  minPassLengthMessage:
    'You have entered an invalid password. Please use 10-20 characters.',
  unpredictedError:
    'Oops, something went wrong. please try again in a few minutes',
  atMaintenance:
    'We are doing a quick upgrade! This usually takes no more than 10 minutes, so please come back later.',
  jwtExpired: 'session is expired please login again',
  unauthorized: 'this page is restricted to logged in users only ',
  firstNameValidation:
    'First name can contain only letters (a-z) and must be 2-20 characters long.',
  lastNameValidation:
    'Lastname names can contain only letters (a-z) and must be 2-20 characters long.',
  invalid_password_message:
    'Your password must have at least 1 alphabetic character, 1 numeric digit, 1 special character (!@#$%^&*()_+), and be between 10 and 20 characters long.',
  invalidEmailMessage: 'Invalid email',
  noChangesMade: 'No changes has been made.',
  invalidCharsAtName:
    "Name can contain only letters (a-z), numbers (0-9) and _ - ' ' @ .",
  emailsMustMatch: 'Both emails must be the same',
  contentCanNotBeEmpty: 'Content can not be Empty',
  deleteConfirmation: toDelete => `Are you sure you want to delete ${toDelete}`,
  varIsRequired: variableName => `${variableName} is required`,
  varLengthMustBeSmallerThen: (variableName, maxLength) =>
    `${variableName} must be at most ${maxLength} characters`,
  hexColor: 'Color must contain only HexDecimal characters and start with #',
  searchMustBeLongerThen3: 'Search phrase must contain at least 3 characters',
  sendEmailIgnoreFileErrors:
    'Note: attached files has some errors. Would you like to send this email without those files?',
  toContactsCantBeEmpty: isReply =>
    isReply
      ? 'To contacts cant be empty.'
      : 'Please specify at least one recipient.',
  affiliateCompanyNameRequired: 'Company name or website is required'
};
export default userErrorMessageEnum;
