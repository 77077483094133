const serverPrefix = '/api';
const affiliatePrefix = '/affiliate';
const companyPrefix = '/company';
const managerPrefix = '/manager';
const forexPrefix = serverPrefix + managerPrefix + '/forex';
const symbolGroupingPrefix = '/symbol-groupings';
const settingsPrefix = '/settings';
const contactUsPrefix = '/contact-us';
const customStatusesUrl =
  `${serverPrefix}${managerPrefix}${companyPrefix}` + '/custom-statuses/';

const ajaxUtils = {
  twoFactor: '/2fa',
  campaignLogoFilePath: '/register-campaign-logo',
  translations: `${serverPrefix}/translation-keys`,
  datagrid: {
    root: `${serverPrefix}/datagrid/state`,
    id: id => `${serverPrefix}/datagrid/state/${id}`
  },
  shortUrl: `${serverPrefix}/url-shortener`,
  configActions: {
    config: `${serverPrefix}/config`,
    companyInitialData: `${serverPrefix}/company/initial-data`,
    affiliateDocReq: `${serverPrefix}/documents/affiliate`
  },
  companyActions: {
    trafficSources: `${serverPrefix}${affiliatePrefix}${companyPrefix}/traffic-sources`,
    advertisers: `${serverPrefix}${affiliatePrefix}${companyPrefix}/advertisers`,
    currency: `${serverPrefix}${affiliatePrefix}${companyPrefix}/currency`,
    merchant: `${serverPrefix}${affiliatePrefix}${companyPrefix}/merchant`,
    affiliates: `${serverPrefix}${affiliatePrefix}${companyPrefix}/affiliates`,
    termsAndConditions: `${serverPrefix}${affiliatePrefix}${companyPrefix}/terms-and-conditions`,
    privacyPolicy: `${serverPrefix}${affiliatePrefix}${companyPrefix}/privacy-policy`,
    customFields: `${serverPrefix}${affiliatePrefix}${companyPrefix}/custom-fields`,
    customStatuses: `${customStatusesUrl}`,
    customStatusesAllActive: `${customStatusesUrl}all-active`,
    branding: `${serverPrefix}${affiliatePrefix}${companyPrefix}/branding`,
    settings: `${serverPrefix}${affiliatePrefix}${companyPrefix}/settings`,
    statistics: {
      dailyStats: {
        financial: `${serverPrefix}${affiliatePrefix}${companyPrefix}/financial-daily-stats/filtered`,
        iGaming: `${serverPrefix}${affiliatePrefix}${companyPrefix}/igaming-daily-stats/filtered`,
        filtered: `${serverPrefix}${affiliatePrefix}${companyPrefix}/campaign-daily-stats/filtered`
      },
      leads: {
        get: `${serverPrefix}${affiliatePrefix}${companyPrefix}/leads`
      },
      conversions: {
        all: `${serverPrefix}${affiliatePrefix}${companyPrefix}/conversion-stats`,
        lastWeek: `${serverPrefix}${affiliatePrefix}${companyPrefix}/conversion-stats/last-week`,
        filtered: `${serverPrefix}${affiliatePrefix}${companyPrefix}/conversion-stats/filtered`
      },
      impressions: {
        get: `${serverPrefix}${affiliatePrefix}${companyPrefix}/impressions`
      },
      clicks: {
        get: `${serverPrefix}${affiliatePrefix}${companyPrefix}/clicks`
      }
    }
  },
  docs: {
    company: { get: '/api/documents/company' },
    download: uuid => `/api/documents/${uuid}`,
    user: { upload: uuid => `/api/documents/upload-docs/${uuid}` }
  },
  paymentMethods: {
    get: '/api/affiliate/payouts/settings',
    tipalti: '/api/affiliate/payouts/settings/tipalti',
    delete: uuid => `/api/affiliate/payouts/settings/${uuid}`
  },
  userActions: {
    affiliate: {
      campaigns: `${serverPrefix}${affiliatePrefix}/campaigns`,
      campaignsCreativeLinks: `${serverPrefix}${affiliatePrefix}/campaigns/tracking-data/creative-links`,
      campaignsSearch: `${serverPrefix}${affiliatePrefix}/campaigns/search`,
      advertisers: `${serverPrefix}${affiliatePrefix}/advertisers`,
      updateUserInfo: `${serverPrefix}${affiliatePrefix}/update-user-info`,
      companies: `${serverPrefix}${affiliatePrefix}/companies`,
      countries: `${serverPrefix}${affiliatePrefix}/countries`
    },
    settings: {
      personalization: `${serverPrefix}/settings/personalization`,
      updateGeneralSettings: `${serverPrefix}/settings/general`,
      updatePasswordSettings: `${serverPrefix}/settings/password`,
      updateEmailSettings: `${serverPrefix}/change-user-email`,
      updatePostbackSettings: `${serverPrefix}/settings/postback`,
      updateTheme: `${serverPrefix}/settings/theme`,
      deleteAccount: `${serverPrefix}${settingsPrefix}/delete-account`,
      updateTerms: `${serverPrefix}${settingsPrefix}/update-terms`
    },
    registerAffiliate: '/register-affiliate',
    login: '/login',
    adminLogin: '/admin-login',
    loginWithToken: '/login-with-token',
    forgotPassword: '/forgot-password',
    changeForgottenPassword: '/change-forgotten-password',
    unsubscribe: '/unsubscribe',
    resetPassword: '/reset',
    activateUser: '/activate',
    updateUserInfo: `${serverPrefix}update-user-info`,
    updatePassword: `${serverPrefix}change-user-password`,
    updateEmail: `${serverPrefix}change-user-email`,
    uploadUserPic: `${serverPrefix}upload-user-profile-pic`,
    removeUserPic: `${serverPrefix}remove-user-profile-pic`,
    downloadProfileImage: `${serverPrefix}download-user-profile-pic`,
    validateOwnerShip: '/login',
    logout: '/logout',
    refreshToken: '/refresh-token',
    clientData: `/client-data`
  },
  inboxActions: {
    add: `${serverPrefix}add-inbox`,
    edit: `${serverPrefix}update-inbox`,
    remove: `${serverPrefix}remove-inbox`,
    updateFollowing: `${serverPrefix}update-following`,
    getDefaultFilters: `${serverPrefix}get-default-filters`,
    getContactsAddresses: `${serverPrefix}get-contacts-addresses`,
    getInboxesCounters: `${serverPrefix}/get-inboxes-counter`,
    getTags: `${serverPrefix}get-tags`,
    addTag: `${serverPrefix}add-tag`,
    updateTag: `${serverPrefix}update-tag`,
    updateUserInbox: `${serverPrefix}update-user-inbox`
  },
  membersActions: {
    inviteMembers: `${serverPrefix}invite-members`
  },
  teamsActions: {
    add: `${serverPrefix}add-new-team`,
    getTeam: `${serverPrefix}get-team`
  },
  attachmentActions: {
    uploadAttachment: `${serverPrefix}upload-attachment`,
    uploadInlineAttachment: `${serverPrefix}upload-inline-attachment`,
    getAttachment: `${serverPrefix}attachment`,
    removeAttachment: `${serverPrefix}remove-attachment`,
    downloadAttachment: `${serverPrefix}download-attachment`
  },
  signatureActions: {
    add: `${serverPrefix}add-signature`,
    update: `${serverPrefix}update-signature`,
    updateName: `${serverPrefix}update-signature-name`,
    delete: `${serverPrefix}delete-signature`,
    get: `${serverPrefix}get-signatures`
  },
  payouts: `${serverPrefix}${affiliatePrefix}/payouts`,
  dashboard: `${serverPrefix}${affiliatePrefix}/dashboard`,
  newsActions: {
    get: `${serverPrefix}/news`,
    getByCampaign: oid => `${serverPrefix}/news/${oid}`
  },
  forex: {
    registration: {
      get: `${forexPrefix}/registration`,
      edit: id => `${forexPrefix}/registration/${id}`
    },
    accounts: {
      get: `${forexPrefix}/accounts`,
      getByID: id => `${forexPrefix}/account/${id}`,
      edit: (uid, id) => `${forexPrefix}/${uid}/edit-account/${id}`,
      create: uid => `${forexPrefix}/${uid}/account`
    },
    transactions: {
      get: `${forexPrefix}/transactions`,
      create: `${forexPrefix}/transaction`,
      getByID: id => `${forexPrefix}/transaction/${id}`
    }
  },
  groupings: {
    symbol: {
      getAvailable: `${serverPrefix}${symbolGroupingPrefix}/availableSymbols`,
      getAll: `${serverPrefix}${symbolGroupingPrefix}/allSymbols`,
      get: `${serverPrefix}${symbolGroupingPrefix}`,
      options: `${serverPrefix}${symbolGroupingPrefix}/options`,
      save: `${serverPrefix}${symbolGroupingPrefix}`,
      symbol: { save: `${serverPrefix}${symbolGroupingPrefix}/symbol` },

      update: uuid => `${serverPrefix}${symbolGroupingPrefix}/${uuid}`
    }
  },
  contactUs: `${serverPrefix}${contactUsPrefix}/send-email`,
  apiKeys: {
    root: `${serverPrefix}${affiliatePrefix}/apiKeys`,
    edit: uuid => `${serverPrefix}${affiliatePrefix}/apiKeys/${uuid}`
  }
};
export default ajaxUtils;
