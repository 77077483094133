import { Box, Divider } from '@mui/material';
import LanguagePopover from 'src/layouts/DashboardLayout/TopBar/LanguagePopover';

function LanguageButtons() {
  return (
    <Box my={2} display="flex" justifyContent="center">
      <Divider />
      <LanguagePopover showLang />
    </Box>
  );
}

export default LanguageButtons;
