import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, SvgIcon, TextField } from '@mui/material';
import PropTypes from 'prop-types';

function SearchBar({ handleQueryChange, placeholder, query, width = 500 }) {
  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SvgIcon fontSize="small" color="action">
              <SearchIcon />
            </SvgIcon>
          </InputAdornment>
        )
      }}
      onChange={handleQueryChange}
      placeholder={placeholder}
      value={query}
      variant="outlined"
      sx={{
        backgroundColor: theme => theme.palette.background.default,
        width: width
      }}
    />
  );
}

SearchBar.propTypes = {
  handleQueryChange: PropTypes.func,
  placeholder: PropTypes.string,
  query: PropTypes.string
};

export default SearchBar;
