import { Card, CardContent, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { accountSelector, companySelector } from 'src/selectors/selectors';
import CustomDocs from 'src/views/affiliate/Setting/CustomDocs';
import AdminStatusLabel from '../AdminStatusLabel';
import Page from '../Page';

function ManualApprovedGuard({ children, showAlert = true, active = true }) {
  const account = useSelector(accountSelector);
  const company = useSelector(companySelector);
  if (
    active &&
    company?.affiliateNeedsManualActivation &&
    account.user?.adminStatus !== 'APPROVED'
  ) {
    if (!showAlert) return null;
    return (
      <Page maxWidth={false}>
        <Card sx={{ mb: 3 }}>
          <CardContent
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 3
            }}
          >
            <AdminStatusLabel status={account.user} />
            <Typography>
              <FormattedMessage id="we_are_waiting_for_administrative_approval" />
            </Typography>
            {!account?.affiliateDocsRequierd ? (
              <Typography>
                <FormattedMessage id="we_will_let_you_know_once_your_application_is_approved" />
              </Typography>
            ) : (
              <Typography>
                <FormattedMessage id="upload_files_required" />
              </Typography>
            )}
          </CardContent>
        </Card>
        {account?.affiliateDocsRequierd && <CustomDocs requierdOnly={false} />}
      </Page>
    );
  }
  return children;
}

ManualApprovedGuard.propTypes = {
  children: PropTypes.any,
  showAlert: PropTypes.bool
};

export default ManualApprovedGuard;
