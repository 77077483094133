import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormattedMessage } from 'react-intl';

const propTypes = {
  children: PropTypes.node.isRequired
};

const DialogOptions = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string
};

const PromiseInfo = {
  resolve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired
};

const ShowDialogHandler = PropTypes.func.isRequired;
/**
 * @typedef {Object} Options
 * @property {string} options.title - Dialog title.
 * @property {any=} options.originalRequest - originalRequest .
 * @property {string=} options.message - Dialog message (optional).
 * @property {boolean=} options.showCancel - Show Cancel (optional).
 * @property {string=} options.customCancelText - customCancelText (optional).
 * @property {string=} options.customApproveText - customApproveText (optional).
 */
/**
 * Function to show a dialog with the specified options.
 *
 * @callback ShowDialogHandler
 * @param {Options} options - Dialog options.
 * @returns {Promise<boolean>} - A promise that resolves with a boolean indicating the user's confirmation.
 */

/**
 * @type {React.Context<ShowDialogHandler>}
 */
const DialogContext = createContext(ShowDialogHandler);

const RecaptchaProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState({
    title: ''
  });
  const [promiseInfo, setPromiseInfo] = useState();

  /**
   * Function to show a dialog with the specified options.
   *
   * @param {Object} options - Dialog options.
   * @param {string} options.title - Dialog title.
   * @param {string} options.originalRequest - Original Request.
   * @param {string=} options.message - Dialog message (optional).
   * @returns {Promise<boolean>} - A promise that resolves with a boolean indicating the user's confirmation.
   */
  const showDialog = options => {
    return new Promise((resolve, reject) => {
      setPromiseInfo({ resolve, reject });
      setOptions(options);
      setOpen(true);
    });
  };

  const handleConfirm = async value => {
    if (options?.originalRequest) {
      const headers = {
        ...options?.originalRequest.headers,
        'captcha-response': value
      };
      const newRequest = {
        ...options?.originalRequest,
        headers
      }; // Create a new request with the headers and cancel token
      try {
        const reshotResponse = await axios(newRequest);
        setOpen(false); // Close the modal on successful validation
        promiseInfo.resolve(reshotResponse);
        setPromiseInfo(undefined);
      } catch (error) {
        // Handle error
        setOpen(false); // Close the modal on successful validation
        console.log(error);
        promiseInfo.reject(error);
        setPromiseInfo(undefined);
      }
    }
  };

  const handleCancel = () => {
    setOpen(false);
    promiseInfo?.resolve('canceled');
    setPromiseInfo(undefined);
  };

  return (
    <>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{options.title}</DialogTitle>
        <DialogContent sx={{ minWidth: '400px' }}>
          <DialogContentText sx={{ mb: 3 }}>
            <FormattedMessage id="please_fill_captcha" />
          </DialogContentText>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={handleConfirm}
          />
        </DialogContent>
        <DialogActions>
          {options?.showCancel && (
            <Button onClick={handleCancel}>
              <FormattedMessage id={options.customCancelText ?? 'cancel'} />
            </Button>
          )}
          {/* <Button variant="contained" onClick={handleConfirm}>
            <FormattedMessage id={options.customApproveText ?? 'approve'} />
          </Button> */}
        </DialogActions>
      </Dialog>

      <DialogContext.Provider value={showDialog}>
        {children}
      </DialogContext.Provider>
    </>
  );
};

RecaptchaProvider.propTypes = propTypes;
/**
 * Custom hook to access the showDialog function from the DialogContext.
 *
 * @returns {ShowDialogHandler} - The showDialog function.
 */
export const useRecaptchaDialog = () => {
  return useContext(DialogContext);
};

export default RecaptchaProvider;
