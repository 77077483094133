import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { FormattedMessage } from 'react-intl';
import {
  getPrivacyPolicy,
  getTermsAndConditions
} from 'src/actions/companyActions';
import {
  PRIVACY_POLICY_NEEDS_APPROVAL,
  TERMS_AND_CONDITIONS_NEEDS_APPROVAL
} from 'src/utils/staticObjects';

const propTypes = {
  children: PropTypes.node.isRequired
};

const DialogOptions = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string
};

const PromiseInfo = {
  resolve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired
};

const ShowDialogHandler = PropTypes.func.isRequired;
/**
 * @typedef {Object} Options
 * @property {string} options.title - Dialog title.
 * @property {any=} options.originalRequest - originalRequest .
 * @property {string=} options.message - Dialog message (optional).
 * @property {string=} options.type - Dialog type (optional).
 * @property {boolean=} options.showCancel - Show Cancel (optional).
 * @property {string=} options.customCancelText - customCancelText (optional).
 * @property {string=} options.customApproveText - customApproveText (optional).
 */
/**
 * Function to show a dialog with the specified options.
 *
 * @callback ShowDialogHandler
 * @param {Options} options - Dialog options.
 * @returns {Promise<boolean>} - A promise that resolves with a boolean indicating the user's confirmation.
 */

/**
 * @type {React.Context<ShowDialogHandler>}
 */
const DialogContext = createContext(ShowDialogHandler);

const RetryProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState({
    title: ''
  });
  const [promiseInfo, setPromiseInfo] = useState();
  const [merchantPrivacyPolicy, setMerchantPrivacyPolicy] = useState();
  const [
    merchantTermsAndConditions,
    setMerchantTermsAndConditions
  ] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchTermsAndConditions = async () => {
    const currentTerms = await getTermsAndConditions();
    if (currentTerms?.termsAndConditions) {
      setMerchantTermsAndConditions(currentTerms.termsAndConditions);
    }
  };

  const fetchPrivacyPolicy = async () => {
    const currentPrivacy = await getPrivacyPolicy();
    if (currentPrivacy?.privacyPolicy) {
      setMerchantPrivacyPolicy(currentPrivacy.privacyPolicy);
    }
  };

  const fetchData = async () => {
    await Promise.all([fetchTermsAndConditions(), fetchPrivacyPolicy()]);
  };

  /**
   * Function to show a dialog with the specified options.
   *
   * @param {Object} options - Dialog options.
   * @param {string} options.title - Dialog title.
   * @param {string} options.originalRequest - Original Request.
   * @param {string=} options.message - Dialog message (optional).
   * @returns {Promise<boolean>} - A promise that resolves with a boolean indicating the user's confirmation.
   */
  const showDialog = options => {
    return new Promise((resolve, reject) => {
      setPromiseInfo({ resolve, reject });
      setOptions(options);
      setOpen(true);
    });
  };

  const handleConfirm = async value => {
    if (options?.originalRequest) {
      const headers = {
        ...options?.originalRequest.headers,
        'retry-response': true
      };
      const newRequest = {
        ...options?.originalRequest,
        headers
      }; // Create a new request with the headers and cancel token
      try {
        const reshotResponse = await axios(newRequest);
        setOpen(false); // Close the modal on successful validation
        promiseInfo.resolve(reshotResponse);
        setPromiseInfo(undefined);
      } catch (error) {
        // Handle error
        setOpen(false); // Close the modal on successful validation
        console.log(error);
        promiseInfo.reject(error);
        setPromiseInfo(undefined);
      }
    }
  };

  const handleCancel = () => {
    setOpen(false);
    promiseInfo?.resolve('canceled');
    setPromiseInfo(undefined);
  };

  const message = useMemo(() => {
    switch (options?.type) {
      case PRIVACY_POLICY_NEEDS_APPROVAL:
        return merchantPrivacyPolicy;
      case TERMS_AND_CONDITIONS_NEEDS_APPROVAL:
        return merchantTermsAndConditions;
      default:
        return options?.message;
    }
  }, [options?.type]);

  return (
    <>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{options.title}</DialogTitle>
        <DialogContent sx={{ minWidth: '400px' }}>
          <DialogContentText sx={{ mb: 3 }}>
            {options?.type ? (
              <div
                style={{ whiteSpace: 'pre-wrap' }}
                dir="auto"
                dangerouslySetInnerHTML={{ __html: message }}
              />
            ) : (
              message
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm}>
            <FormattedMessage id={'i_agree'} />
          </Button>
          {/* <Button variant="contained" onClick={handleConfirm}>
            <FormattedMessage id={options.customApproveText ?? 'approve'} />
          </Button> */}
          {options?.showCancel && (
            <Button onClick={handleCancel}>
              <FormattedMessage id={options.customCancelText ?? 'cancel'} />
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <DialogContext.Provider value={showDialog}>
        {children}
      </DialogContext.Provider>
    </>
  );
};

RetryProvider.propTypes = propTypes;
/**
 * Custom hook to access the showDialog function from the DialogContext.
 *
 * @returns {ShowDialogHandler} - The showDialog function.
 */
export const useRetryDialog = () => {
  return useContext(DialogContext);
};

export default RetryProvider;
