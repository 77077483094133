import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { accountSelector } from 'src/selectors/selectors';

export default function useGoogleTagManager(gtmId) {
  const account = useSelector(accountSelector);

  useEffect(() => {
    if (gtmId) TagManager.initialize({ gtmId });
  }, [gtmId]);
  useEffect(() => {
    const user = account?.user;
    if (gtmId && user)
      try {
        TagManager.dataLayer({
          dataLayer: {
            event: 'affiliateDetails',
            affiliateName: `${user?.firstName} ${user?.lastName}`, // Replace with actual affiliate name
            affiliateId: user?.oid // Replace with actual affiliate ID
          }
        });
      } catch (error) {}
  }, [gtmId, account]);
}
