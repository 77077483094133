/*
* request uploaded attachment from server (download)
*/
import navigationEnum from '../enums/navigationEnum';
import { downloadProfileImage } from '../../actions/userActions';

const getUserProfileImage = async (user, requestedSize, setImgUrl) => {
  const itemToDownload = user.profileImage;
  const uploadType = navigationEnum.profileAttachment;
  await downloadProfileImage({ token: itemToDownload, uploadType, requestedSize }).then((data) => {
    setImgUrl(data);
  });
};
export default getUserProfileImage;
