// eslint-disable-next-line import/prefer-default-export
export const UserPermissionsEnum = {
  ADVERTISER: { key: 'advertiser', value: 'advertiser' },
  AFFILIATE_CAMPAIGN: {
    key: 'affiliateCampaign',
    value: 'affiliateCampaign'
  },
  AFFILIATE_PAYOUT: { key: 'affiliatePayout', value: 'affiliatePayout' },
  CONVERSIONS: { key: 'conversions', value: 'conversions' },
  COMPANY: { key: 'company', value: 'company' },
  PAYOUTS: { key: 'payouts', value: 'payouts' },
  CLICKS: { key: 'clicks', value: 'clicks' },
  IMPRESSIONS: { key: 'impressions', value: 'impressions' },
  REFERRALS: { key: 'referrals', value: 'referrals' },
  FINANCIAL: { key: 'financial', value: 'financial' },
  FINANCIAL_USERS: { key: 'financialUsers', value: 'financialUsers' },
  FINANCIAL_ACCOUNTS: {
    key: 'financialAccounts',
    value: 'financialAccounts'
  },
  FINANCIAL_TRANSACTIONS_PROFIT: {
    key: 'financialTransactionsProfit',
    value: 'financialTransactionsProfit'
  },
  FINANCIAL_TRANSACTIONS_ID: {
    key: 'financialTransactionsId',
    value: 'financialTransactionsId'
  },
  FINANCIAL_USER_BALANCE: {
    key: 'financialUserBalance',
    value: 'financialUserBalance'
  },
  FINANCIAL_ACCOUNT_ID: {
    key: 'financialAccountId',
    value: 'financialAccountId'
  },
  FTD: { key: 'ftd', value: 'ftd' },
  FINANCIAL_TRANSACTIONS: {
    key: 'financialTransactions',
    value: 'financialTransactions'
  },
  FINANCIAL_WITHDRAWALS: {
    key: 'financialWithdrawals',
    value: 'financialWithdrawals'
  },
  FINANCIAL_DEPOSITS: {
    key: 'financialDeposits',
    value: 'financialDeposits'
  },
  FINANCIAL_NET_DEPOSITS: {
    key: 'financialNetDeposits',
    value: 'financialDeposits'
  },
  FINANCIAL_REPORTS: {
    key: 'reports',
    value: 'financialReports'
  },
  API_KEYS: {
    key: 'apiKeys',
    value: 'apiKeys'
  },
  LEADS: {
    key: 'leads',
    value: 'leads'
  }
};
