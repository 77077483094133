/* eslint-disable react/no-array-index-key */
import DownloadIcon from '@mui/icons-material/Download';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ajaxUtils from 'src/utils/ajaxUtils';
import { download } from 'src/utils/axios';
import bytesToSize from 'src/utils/bytesToSize';
import { isImage } from 'src/utils/utils';
import FileStatusLabel from './FileStatusLabel';

const acceptedFileTypes = ['image/*', 'application/pdf'];

const useStyles = makeStyles(theme => ({
  image: {
    width: 130,
    maxHeight: 100,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  info: {
    marginTop: theme.spacing(1)
  },
  list: {
    maxHeight: 320
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

function FilesDropzone({
  className,
  uploadFiles = () => {},
  existingFiles = [],
  ...rest
}) {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const baseImagesUrl = process.env.REACT_APP_CUSTOM_DOCS_BASE_URL;

  const handleDrop = useCallback(acceptedFiles => {
    setFiles(prevFiles => [...prevFiles].concat(acceptedFiles));
  }, []);

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const data = new FormData();
      files.forEach(f => data.append('files', f));
      await uploadFiles(data);
      handleRemoveAll();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: acceptedFileTypes.join(',') // Specify allowed file types
  });

  return (
    <Box className={clsx(classes.root, className)} {...rest}>
      <Box
        sx={[
          {
            border: theme => `1px dashed ${theme.palette.divider}`,
            padding: 6,
            outline: 'none',
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            alignItems: 'center',
            '&:hover': {
              backgroundColor: theme => theme.palette.action.hover,
              opacity: 0.5,
              cursor: 'pointer'
            }
          },
          isDragActive
            ? {
                backgroundColor: theme => theme.palette.action.active,
                opacity: 0.5
              }
            : {}
        ]}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Box>
          <img
            alt="Select file"
            className={classes.image}
            src="/static/images/undraw_add_file2_gvbb.svg"
          />
        </Box>
        <Box>
          <Typography gutterBottom variant="h3">
            <FormattedMessage id="select_files" />
          </Typography>
          <Box mt={2}>
            <Typography color="textPrimary" variant="body1">
              <FormattedMessage id="drop_files_here_or_click_browse_through_your_machine" />
            </Typography>
          </Box>
        </Box>
      </Box>
      {existingFiles?.length > 0 && (
        <>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <List className={classes.list}>
              {existingFiles.map((file, i) => (
                <ListItem divider={i < files.length - 1} key={i}>
                  <ListItemIcon>
                    {isImage(file?.documentFileLocalPath) ? (
                      <img
                        alt="Select file"
                        className={classes.image}
                        src={`${baseImagesUrl}/${file?.documentFileLocalPath}`}
                      />
                    ) : (
                      <FileCopyIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={file?.name}
                    primaryTypographyProps={{
                      variant: 'h5',
                      title: file?.name,
                      sx: { overflowWrap: 'anywhere', ...styles.truncatedText }
                    }}
                    secondary={
                      <>
                        <FileStatusLabel status={file?.status} />{' '}
                        <Typography
                          title={file?.description}
                          variant="body1"
                          sx={styles.truncatedText}
                        >
                          {file?.description}
                        </Typography>
                      </>
                    }
                  />
                  <Tooltip title="Download">
                    <IconButton
                      onClick={() =>
                        download(`${ajaxUtils.docs.download(file?.id)}`)
                      }
                      edge="end"
                      size="large"
                    >
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                </ListItem>
              ))}
            </List>
          </PerfectScrollbar>
          <Divider />
        </>
      )}
      {files.length > 0 && (
        <>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <List className={classes.list}>
              {files.map((file, i) => (
                <ListItem divider={i < files.length - 1} key={i}>
                  <ListItemIcon>
                    <FileCopyIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={file.name}
                    primaryTypographyProps={{ variant: 'h5' }}
                    secondary={bytesToSize(file?.size)}
                  />
                  {/* <Tooltip title="More options">
                    <IconButton edge="end" size="large">
                      <MoreIcon />
                    </IconButton>
                  </Tooltip> */}
                </ListItem>
              ))}
            </List>
          </PerfectScrollbar>
          <div className={classes.actions}>
            <Button disabled={loading} onClick={handleRemoveAll} size="small">
              <FormattedMessage id="remove_all" />
            </Button>
            <LoadingButton
              onClick={handleSubmit}
              color="secondary"
              size="small"
              variant="contained"
              loading={loading}
            >
              <FormattedMessage id="upload_files" />
            </LoadingButton>
          </div>
        </>
      )}
    </Box>
  );
}

FilesDropzone.propTypes = {
  className: PropTypes.string
};

export default FilesDropzone;
const styles = {
  truncatedText: {
    maxWidth: '8em', // Adjust this value to control the maximum length
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
};
