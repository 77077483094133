import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { accountSelector } from 'src/selectors/selectors';

function DomainRegisterGuard({ children }) {
  const account = useSelector(accountSelector);

  if (account && account.user) {
    return <Redirect to="/app/affiliate/settings" />;
  }
  return children;
}

DomainRegisterGuard.propTypes = {
  children: PropTypes.any
};

export default DomainRegisterGuard;
