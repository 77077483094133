import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function useEnableScript(key) {
  const displayAccessibilityToolbox = useSelector(
    state => state?.company?.displayAccessibilityToolbox
  );

  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');

    if (displayAccessibilityToolbox) {
      script.setAttribute(
        'src',
        `https://cdn.enable.co.il/licenses/enable-${key}/init.js`
      );
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('defer', true);
      head.appendChild(script);

      return () => {
        head.removeChild(script);
      };
    } else {
      const enableToolbarContent = document.getElementById(
        'enable-toolbar-content'
      );
      if (enableToolbarContent) {
        enableToolbarContent.remove();
      }
    }
  }, [key, displayAccessibilityToolbox]);
}
