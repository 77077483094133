import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  brandingSelector,
  companyLogoOverrideSelector
} from 'src/selectors/selectors';
import { getCurrentBrandingRedux } from '../actions/brandingActions';
import { THEMES } from '../constants';
import useSettings from '../hooks/useSettings';
import { Skeleton } from '@mui/material';

function Logo({
  color = 'white',
  isNegativeMode = false,
  onlyTracknow = false,
  campaignLogoOverride = false,
  ...props
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const companyLogoOverride = useSelector(companyLogoOverrideSelector);
  const branding = useSelector(brandingSelector);
  const logoPath = !companyLogoOverride
    ? branding?.brandLogoFilePath
    : companyLogoOverride;
  const params = new URLSearchParams(window.location.search);
  // REACT_APP_CAMPAIGN_LOGO_BASE_URL

  const baseImagesUrl = !!companyLogoOverride
    ? process.env.REACT_APP_CAMPAIGN_LOGO_BASE_URL
    : process.env.REACT_APP_BRAND_LOGO_BASE_URL;

  const config = useSelector(state => state?.config);
  const inMainDomain = config?.domain?.mainDomain;
  const isMobileNavOpen = config?.isMobileNavOpen;
  const domain = useSelector(state => state?.config?.domain?.domainAlias);

  const fetchData = async () => {
    if (!branding && !inMainDomain) {
      dispatch(getCurrentBrandingRedux());
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const { settings } = useSettings();
  // if (isMobileNavOpen) {

  color =
    (isNegativeMode || isMobileNavOpen) && settings.theme === THEMES.LIGHT.name
      ? 'dark'
      : 'white';
  // }

  const defaultLogo = `/static/logos/tracknow-logo-${color}.webp`;
  const imgUrl =
    !!campaignLogoOverride && !!companyLogoOverride
      ? `${baseImagesUrl}${companyLogoOverride}`
      : !onlyTracknow && logoPath
      ? `${baseImagesUrl}${logoPath}`
      : defaultLogo;
  return (
    Boolean(logoPath || onlyTracknow) &&
    (!loading ? (
      <img
        alt={domain}
        src={imgUrl}
        style={{ maxWidth: 200, maxHeight: 50 }}
        {...props}
      />
    ) : (
      <Skeleton sx={{ width: '200px', height: '50px' }} />
    ))
  );
}

export default Logo;
