import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { accountSelector, brandingSelector } from 'src/selectors/selectors';
import authService from '../../services/authService';

function DomainLoginGuard({ children }) {
  const account = useSelector(accountSelector);
  const brandnig = useSelector(brandingSelector);

  const { isUnauthorized } = useSelector(state => state.userInterface);

  const accessToken = authService.getAccessToken();
  const accessTokenValid = !!accessToken && accessToken !== '';

  if (accessTokenValid && account.user && !isUnauthorized) {
    return (
      <Redirect
        to={brandnig?.customHomePage ? '/app/home' : '/app/reports/dashboard'}
      />
    );
  }
  return children;
}

DomainLoginGuard.propTypes = {
  children: PropTypes.any
};

export default DomainLoginGuard;
