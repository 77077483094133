// eslint-disable-next-line import/prefer-default-export
export class ValidationError {
  constructor(valid, msg) {
    this.valid = valid;
    this.msg = msg;
  }

  isValid() { return this.valid; }

  getMsg() { return this.msg; }
}
