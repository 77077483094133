import { Tooltip } from '@mui/material';
import { getCountryName } from 'src/utils/utils';

function FlagIcon({ countryCode, width = 16, height = 12 }) {
  const name = getCountryName(countryCode);
  const code = countryCode?.toString()?.toLowerCase();
  return (
    Boolean(countryCode) && (
      <Tooltip title={name}>
        <img
          src={`https://flagcdn.com/16x12/${code}.png`}
          srcSet={`https://flagcdn.com/32x24/${code}.png 2x,
      https://flagcdn.com/48x36/${code}.png 3x`}
          width={width}
          height={height}
          alt={name}
        />
      </Tooltip>
    )
  );
}

export default FlagIcon;
