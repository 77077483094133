import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import StylesProvider from '@mui/styles/StylesProvider';
import createStyles from '@mui/styles/createStyles';
import jssPreset from '@mui/styles/jssPreset';
import makeStyles from '@mui/styles/makeStyles';
import { LicenseInfo } from '@mui/x-license-pro';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { SnackbarProvider } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router } from 'react-router-dom';
import 'src/assets/css/generalOnly.css';
import Auth from 'src/components/Auth';
import CookiesNotification from 'src/components/CookiesNotification';
import ScrollReset from 'src/components/ScrollReset';
import UserScript from 'src/components/UserScript';
import useSettings from 'src/hooks/useSettings';
import { createCustomTheme, getTheme } from 'src/theme';
import Routes from './Routes';
import {
  getCompanyInitialData,
  updateUserDocReq
} from './actions/configActions';
import GlobalErrorModal from './components/GlobalErrorModal';
import GlobalRecaptchaModal from './components/GlobalRecaptchaModal';
import HelmetConfig from './components/Helmet';
import useEnableScript from './hooks/useEnableScript';
import useGoogleTagManager from './hooks/useGoogleTagManager';
import RTL from './layouts/RtlProvider';
import DialogProvider from './providers/DialogProvider';
import RecaptchaProvider from './providers/RecaptchaProvider';
import RetryProvider from './providers/RetryProvider';
import TranslationProvider from './providers/TranslationProvider';
import TwoFactorProvider from './providers/TwoFactorProvider';
import {
  accountSelector,
  brandingSelector,
  companySelector
} from './selectors/selectors';
import PermissionsUtils from './utils/security/PermissionsUtils';
import { updateUserPaymentMethodsRedux } from './actions/userActions';
import { getCampaignLogoFromSerialOid } from './actions/campaignActions';
import { COMPANY_LOGO_OVERRIDE } from './actions/companyActions';
import { LanguagesEnum } from './constants';

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENCE);

const isOnAuthPage =
  window.location.pathname === '/register' ||
  window.location.pathname === '/forgotPassword' ||
  window.location.pathname === '/login';
function App() {
  const { settings, saveSettings } = useSettings();
  const brandnig = useSelector(brandingSelector);
  const { companyDefaultLanguage } = useSelector(companySelector);
  const account = useSelector(accountSelector);
  const cid = new URLSearchParams(window.location.search)?.get('cid');
  const userGTM = brandnig?.tagManagerId;
  const primaryColor = brandnig?.primaryColor;
  const secondaryColor = brandnig?.secondaryColor;
  const primaryDarkColor = useSelector(
    state => brandingSelector(state)?.primaryDarkColor
  );
  const secondaryDarkColor = useSelector(
    state => brandingSelector(state)?.secondaryDarkColor
  );
  const useStylesLTR = makeStyles(() => {
    let t = getTheme(settings);
    return createStyles({
      '@global': {
        '*': {
          boxSizing: 'border-box',
          margin: 0,
          padding: 0,
          fontFamily: 'Roboto, sans-serif'
        },
        html: {
          '-webkit-font-smoothing': 'antialiased',
          '-moz-osx-font-smoothing': 'grayscale',
          height: '100%',
          width: '100%'
        },
        body: {
          height: '100%',
          width: '100%'
        },
        '#root': {
          height: '100%',
          width: '100%'
        },
        '*::-webkit-scrollbar': {
          width: '0.4em'
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-corner': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.1)',
          outline: '0px solid slategrey',
          borderRadius: 10
        },
        '.MuiDataGrid-root': {
          borderWidth: 0
        }
        // '.MuiDataGrid-root': {
        //   '& .MuiDataGrid-columnHeaderTitle': {
        //     whiteSpace: 'normal',
        //     lineHeight: 'normal',
        //     color: t.palette.text.primary
        //   },
        //   '& .MuiDataGrid-columnHeader': {
        //     // Forced to use important since overriding inline styles
        //     height: 'unset !important'
        //   },
        //   '& .MuiDataGrid-columnHeaders': {
        //     // Forced to use important since overriding inline styles
        //     maxHeight: '168px !important'
        //   }
        // }
      }
    });
  });

  const useStylesRTL = makeStyles(() => {
    let t = getTheme(settings);

    return createStyles({
      '@global': {
        '*': {
          boxSizing: 'border-box',
          margin: 0,
          padding: 0,
          fontFamily: '"Open Sans Hebrew",Roboto, serif;'
        },
        // '* :not(form, restrictedCssArea, .demo-wrapper .rdw-editor-wrapper)': {
        //   fontSize: '0.875rem'
        // },
        html: {
          '-webkit-font-smoothing': 'antialiased',
          '-moz-osx-font-smoothing': 'grayscale',
          height: '100%',
          width: '100%'
        },
        body: {
          height: '100%',
          width: '100%'
        },
        '#root': {
          height: '100%',
          width: '100%'
        },
        '*::-webkit-scrollbar': {
          width: '0.4em'
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-corner': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.1)',
          outline: '0px solid slategrey',
          borderRadius: 10
        },
        '.MuiDataGrid-root': {
          borderWidth: 0
        }
        // '.MuiDataGrid-root': {
        //   direction: 'ltr !important',
        //   transform: ' rotateY(180deg)',
        //   '& .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary': {
        //     transform: 'rotateY(180deg)'
        //   },
        //   '& .MuiDataGrid-overlay': {
        //     transform: 'rotateY(180deg)'
        //   },

        //   '& .MuiDataGrid-columnHeaderTitle': {
        //     transform: 'rotateY(180deg)',
        //     whiteSpace: 'normal',
        //     lineHeight: 'normal',
        //     direction: 'rtl',
        //     color: t.palette.text.primary
        //   },

        //   '& .MuiDataGrid-cell': {
        //     transform: 'rotateY(180deg)',
        //     textAlign: 'right !important',
        //     color: t.palette.text.primary
        //   },

        //   '& .MuiDataGrid-footerContainer': {
        //     transform: 'rotateY(180deg)'
        //   },
        //   '& .MuiTablePagination-actions': {
        //     '& .MuiButtonBase-root': {
        //       transform: 'rotateY(180deg)'
        //     }
        //   },

        //   '& .MuiButton-startIcon.MuiButton-iconSizeSmall': {
        //     marginLeft: '-2px !important;',
        //     marginRight: '8px !important;'
        //   },

        //   '& .MuiDataGrid-columnHeader': {
        //     // Forced to use important since overriding inline styles
        //     height: 'unset !important'
        //   },
        //   '& .MuiDataGrid-columnHeaders': {
        //     // Forced to use important since overriding inline styles
        //     maxHeight: '168px !important'
        //   }
        // }
      }
    });
  });

  const dispatch = useDispatch();
  if (settings.direction === 'ltr') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useStylesLTR();
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useStylesRTL();
  }

  useEffect(() => {
    fetchData();
    fetchLogo();
  }, []);

  useEffect(() => {
    if (companyDefaultLanguage === 'EN') return;
    if (settings?.isDefaultSettings) {
      saveSettings({
        lang: companyDefaultLanguage ?? 'EN',
        direction: LanguagesEnum[companyDefaultLanguage ?? 'EN']?.direction,
        isDefaultSettings: false
      });
    }
  }, [companyDefaultLanguage]);

  useEffect(() => {
    if (account?.user) {
      updateUserDocReq();
      updateUserPaymentMethodsRedux();
    }
  }, [account?.user]);

  useEnableScript(process.env.REACT_APP_ENABLE_KEY);
  //break in case of emargency
  // useGoogleTagManager(process.env.REACT_APP_GTM_ID); this is our tagmanager currently disabled

  useGoogleTagManager(userGTM);

  const fetchData = async () => {
    await dispatch(getCompanyInitialData());
  };

  const hasValidCampaignId = cid => {
    return !!cid && !Number.isNaN(Number(cid));
  };
  const fetchLogo = () => {
    if (hasValidCampaignId(cid)) {
      getCampaignLogoFromSerialOid(cid).then(logo => {
        if (logo) {
          dispatch({
            type: COMPANY_LOGO_OVERRIDE,
            payload: logo
          });
        }
      });
    } else {
      if (isOnAuthPage) {
        dispatch({
          type: COMPANY_LOGO_OVERRIDE,
          payload: null
        });
      }
    }
  };

  const customTheme = useMemo(
    () =>
      createCustomTheme(
        settings,
        primaryColor,
        secondaryColor,
        primaryDarkColor,
        secondaryDarkColor
      ),
    [
      settings,
      settings?.lang,
      primaryColor,
      secondaryColor,
      primaryDarkColor,
      secondaryDarkColor
    ]
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={customTheme}>
        <StylesProvider jss={jss}>
          <TranslationProvider>
            <RTL isRtl={settings.direction === 'rtl'}>
              <SnackbarProvider maxSnack={1}>
                <DialogProvider>
                  <RetryProvider>
                    <TwoFactorProvider>
                      <RecaptchaProvider>
                        <Router history={history}>
                          <GlobalErrorModal />
                          <GlobalRecaptchaModal />
                          <PermissionsUtils />
                          <Auth>
                            <ScrollReset />
                            <UserScript />
                            <CookiesNotification />
                            <HelmetConfig />
                            {/* for now its has been disabled */}
                            {/* <SettingsNotification /> */}
                            <Routes />
                          </Auth>
                        </Router>
                      </RecaptchaProvider>
                    </TwoFactorProvider>
                  </RetryProvider>
                </DialogProvider>
              </SnackbarProvider>
            </RTL>
          </TranslationProvider>
        </StylesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
