import { store } from 'src';
import ajaxUtils from 'src/utils/ajaxUtils';
import { get } from 'src/utils/axios';

export const OPEN_NEW_MESSAGE = '@userInterface/open-new-message';
export const OPEN_NEW_REPLY_MESSAGE = '@userInterface/open-new-reply-message';
export const CLOSE_NEW_REPLY_MESSAGE = '@userInterface/close-new-reply-message';
export const CLOSE_NEW_MESSAGE = '@userInterface/close-new-message';
export const RESET_PAGING = '@userInterface/reset_paging';
export const PAGING_RESTED = '@userInterface/paging_rested';
export const SERVER_DOWN = '@userInterface/server_down';
export const UNAUTHORIZED_USER = '@userInterface/unauthorized_user';
export const SET_IS_LOADING = '@userInterface/set_is_loading';
export const DRAFT_SAVED = '@userInterface/draft_saved';
export const UPDATE_TRANSLATIONS = '@userInterface/update-translations';

export function draftSaved(
  normalizedMail,
  draftStatus,
  folderName,
  isNewDraft
) {
  return {
    type: DRAFT_SAVED,
    payload: {
      draftStatus,
      normalizedMail,
      folderName,
      isNewDraft
    }
  };
}

export function openNewMessage(mailOid) {
  return {
    type: OPEN_NEW_MESSAGE,
    payload: {
      mailOid
    }
  };
}
export function openReplyMessage(mailOid) {
  return {
    type: OPEN_NEW_REPLY_MESSAGE,
    payload: {
      mailOid
    }
  };
}
export function closeReplyMessage() {
  return {
    type: CLOSE_NEW_REPLY_MESSAGE
  };
}
export function closeNewMessage(draftOid, folderName) {
  return {
    type: CLOSE_NEW_MESSAGE,
    payload: {
      draftOid,
      folderName
    }
  };
}

export function resetPaging(folderName) {
  return {
    type: RESET_PAGING,
    payload: {
      folderName
    }
  };
}

export function pagingRested() {
  return {
    type: PAGING_RESTED
  };
}

export function unauthorizedUser() {
  return {
    type: UNAUTHORIZED_USER
  };
}
export function setLoadingMode(loadingMode) {
  return {
    type: SET_IS_LOADING,
    payload: {
      loadingMode
    }
  };
}

const getTranslations = async () => {
  const response = await get(ajaxUtils.translations);
  return response?.data;
};

export const updateTranslations = async () => {
  const response = await getTranslations();
  if (response)
    await store.dispatch({
      type: UPDATE_TRANSLATIONS,
      payload: response
    });
  return;
};
