import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import authService from 'src/services/authService';
import { logout } from '../actions/userActions';

function Auth({ children }) {
  const dispatch = useDispatch();
  const { search: urlToken } = useLocation();
  const tokenIndex = urlToken.indexOf('token=');

  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logout())
      });
      if (tokenIndex === -1) {
        authService.handleAuthentication();

        if (authService.isAuthenticated()) {
          const user = await authService.loginInWithToken();
        }
      } else {
        dispatch(logout());
      }
    };

    initAuth();
  }, [dispatch]);

  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
