import { FormattedMessage } from 'react-intl';
import Label from './Label';

export default function FileStatusLabel({ status }) {
  return (
    <Label color={DocumentStatusColors[status]}>
      <FormattedMessage id={status} />
    </Label>
  );
}
const DocumentStatusColors = {
  APPROVED: 'success',
  REJECTED: 'error',
  PENDING: 'primary',
  NEEDS_ATTENTION: 'warning',
  INCOMPLETE: 'secondary',
  EXPIRED: 'gray'
};
