import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { brandingSelector } from 'src/selectors/selectors';

function UserScript() {
  const userScript = useSelector(state => brandingSelector(state)?.script);
  return (
    <Helmet>
      <script>{userScript}</script>
    </Helmet>
  );
}

export default UserScript;
