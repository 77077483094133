import { combineReducers } from 'redux';
import accountReducer from './accountReducer';
import companyReducer from './companyReducer';
import configReducer from './configReducer';
import userInterfaceReducer from './userInterfaceReducer';

const appReducer = combineReducers({
  account: accountReducer,
  userInterface: userInterfaceReducer,
  config: configReducer,
  company: companyReducer
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
