import { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { getCompanyInitialData } from '../../../actions/configActions';
import { adminLogin } from '../../../actions/userActions';
import LoadingScreen from '../../../components/LoadingScreen';
import authService from '../../../services/authService';

// eslint-disable-next-line react/prop-types
function AdminLoginView() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const adminLoginOnMount = async () => {
    const { accessToken } = params;
    let tokenValid = false;
    if (accessToken) {
      tokenValid = authService.isValidToken(accessToken);
      if (tokenValid) {
        // await authService.setSession(null);
        await authService.setSession(accessToken);
        await dispatch(adminLogin(accessToken));
        await dispatch(getCompanyInitialData());
        history.push('/app/reports/dashboard');
      }
    }
  };

  useEffect(() => {
    adminLoginOnMount();
  }, []);

  return <LoadingScreen />;
}

export default injectIntl(AdminLoginView);
