import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import { useRecaptchaDialog } from 'src/providers/RecaptchaProvider';

//used only to call showDialog inside interceptor
function GlobalRecaptchaModal() {
  const showDialog = useRecaptchaDialog();
  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response && error.response.status === 423) {
        return showDialog({
          title: <FormattedMessage id="please_verify_you_are_not_a_robot" />,
          originalRequest: error.config
        });
      }
      throw error;
    }
  );

  return <div></div>;
}

export default GlobalRecaptchaModal;
