export const permissionsEnum = {
  All:'*',
  NOTING:'-',
  TEAM_SETTINGS_VIEW: 1,
  TEAM_SETTINGS_INBOXES: 2,
  TEAM_SETTINGS_ACTIVE_MEMBERS: 3,
  TEAM_SETTINGS_INVITED_MEMBERS: 4,
  ADD_AND_EDIT_INBOX: 5
};

export const userRolesEnum = {
  "NORMAL_USER": [permissionsEnum.TEAM_SETTINGS_VIEW , permissionsEnum.TEAM_SETTINGS_INBOXES] ,
  "COMPANY_MANAGER": [permissionsEnum.All]
};
