import ajaxUtils from '../utils/ajaxUtils';
import { get } from '../utils/axios';
import { COMPANY_BRANDING } from './companyActions';

// eslint-disable-next-line import/prefer-default-export
export const getCurrentBrandingRedux = () => {
  const request = get(`${ajaxUtils.companyActions.branding}/current`);
  return (dispatch) => {
    request.then((response) => {
      if (!response.data.error) {
        dispatch({
          type: COMPANY_BRANDING,
          payload: response.data
        });
      }
    }).catch((err) => {
      console.warn(err);
    });
  };
};

export const getCurrentBranding = async () => {
  const request = await get(`${ajaxUtils.companyActions.branding}/current`);
  return request?.data;
};
