/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
    DATAGRID_STATE,
    DOC_REQ,
    LOGIN_FAILURE,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGOUT,
    PASSWORD_CHANGED,
    PAYMENT_METHOD,
    PERSONALIZATION,
    SILENT_LOGIN,
    UPDATE_PROFILE,
    UPDATE_PROFILE_IMAGE
} from 'src/actions/userActions';

const initialState = {
  user: null,
  errorMessage: '',
  datagrid: {},
  avatarChanged: false,
  affiliateDocsRequierd: false,
  hasPaymentMethod: false
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.user = null;
      });
    }
    case SILENT_LOGIN:
    case LOGIN_SUCCESS: {
      const { user } = action.payload;
      return produce(state, draft => {
        draft.user = user;
      });
    }
    case LOGIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case LOGOUT: {
      return initialState;
    }

    case UPDATE_PROFILE: {
      const user = action.payload.data;
      return produce(state, draft => {
        draft.user = user;
      });
    }
    case UPDATE_PROFILE_IMAGE: {
      return produce(state, draft => {
        draft.user.profileImage = action.payload;
      });
    }

    case PASSWORD_CHANGED: {
      return produce(state, draft => {
        draft.passwordChaned = true;
      });
    }
    case PERSONALIZATION: {
      const personalization = action.payload;
      return produce(state, draft => {
        draft.user.personalization = personalization;
      });
    }
    case DOC_REQ: {
      const isReq = action.payload;
      return produce(state, draft => {
        draft.affiliateDocsRequierd = isReq;
      });
    }
    case PAYMENT_METHOD: {
      const paymentMethod = action.payload;
      return produce(state, draft => {
        draft.hasPaymentMethod = paymentMethod;
      });
    }
    case DATAGRID_STATE: {
      return produce(state, draft => {
        draft.datagrid = action.payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
