import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { accountSelector } from 'src/selectors/selectors';
import Label from './Label';

export default function AdminStatusLabel() {
  const account = useSelector(accountSelector);
  const status = account?.user?.adminStatus;
  const reason = account?.user?.adminStatusReason;
  return (
    <Box sx={{ display: 'flex', gap: 3 }}>
      <Typography>
        <FormattedMessage id={'status'} />:
      </Typography>

      <Label color={AdminStatusColors[status]}>
        <FormattedMessage id={status} />
      </Label>
      {!!reason && (
        <Typography>
          <FormattedMessage id={'reason'} />:
        </Typography>
      )}
      {!!reason && <Typography>{reason}</Typography>}
    </Box>
  );
}
export const AdminStatusColors = {
  APPROVED: 'success',
  REJECTED: 'error',
  PENDING: 'primary',
  NEEDS_ATTENTION: 'warning',
  INCOMPLETE: 'secondary',
  EXPIRED: 'gray'
};
