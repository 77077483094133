import Alert from '@mui/material/Alert';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { SettingsTabsEnum } from '../utils/staticObjects';
import { FormattedMessage } from 'react-intl';
import { AffiliatePayoutTypeEnum } from '../utils/enums/AffiliatePayoutTypeEnum';

function AddPaymentMethodAlert() {
  const history = useHistory();
  const hasPaymentMethod = useSelector(
    state => state?.account?.hasPaymentMethod
  );
  const affiliatePayoutType = useSelector(
    state => state?.company?.affiliatePayoutType
  );

  return (
    !hasPaymentMethod &&
    affiliatePayoutType === AffiliatePayoutTypeEnum.AUTOMATICALLY.value && (
      <Box sx={{ position: 'sticky', top: 0, zIndex: 9 }}>
        <Alert
          severity="warning"
          action={
            <Button
              onClick={() =>
                history.push(
                  '/app/affiliate/settings#' +
                    SettingsTabsEnum.PaymentMethods.value
                )
              }
              color="inherit"
              size="small"
            >
              <FormattedMessage
                id={'go_to_settings'}
                defaultMessage={'Go To Settings'}
              />
            </Button>
          }
        >
          <FormattedMessage
            id={'add_payment_method'}
            defaultMessage={'Add Payment Method'}
          />
        </Alert>
      </Box>
    )
  );
}

export default AddPaymentMethodAlert;
