/* eslint-disable no-console */
/* eslint-disable indent */
const HOST = process.env.REACT_APP_API_HOST;
const HOST_NAME = process.env.REACT_APP_API_HOST_NAME;
const DOMAIN_NAME = process.env.REACT_APP_AFFILIATE_DOMAIN_NAME;

const serverDetails = {
  path: HOST,
  hostName: HOST_NAME,
  domainName: DOMAIN_NAME,
  mode: 'develop'
};
export default serverDetails;
